<script>
	import { page } from '$app/stores';
	import logo from '$lib/images/mm.svg';
	// import github from '$lib/images/github.svg';

	const DEVMODE = import.meta.env.DEV;

	//---------------------------------
	// spinner
	//---------------------------------
	// https://schum123.github.io/svelte-loading-spinners/
	// https://github.com/Schum123/svelte-loading-spinners
	import { Clock } from 'svelte-loading-spinners';
	import { navigating } from '$app/stores'




	//---------------------------------
	// overlay
	//---------------------------------
	import { onDestroy, onMount } from 'svelte';
	import { tick } from 'svelte';

	let showOverlay = false;
	let timeout;

	// Watch the navigating store and update showOverlay accordingly
	$: {
		if ($navigating) {
			showOverlay = true;
			clearTimeout(timeout);
			timeout = setTimeout(() => {
				showOverlay = false;
			}, 10000);
		}
	}

	// Clean up the timeout when the component is destroyed
	onDestroy(() => {
		clearTimeout(timeout);
	});

	// Ensure the timeout is reset when navigating ends
	$: {
		if (!$navigating) {
			tick().then(() => {
				if (!showOverlay) {
					clearTimeout(timeout);
				}
			});
		}
	}

</script>

<header class="bg-[#427FED]">
	<!-- <div class="corner">
		<div class="flex">
			<a href="/" class="ml-4">
				<img src={logo} alt="ManaMurah" class="w-[32px] h-[32px] hover:opacity-50 max-h-[100px] max-w-[100px]"/>
			</a>

		</div>

	</div> -->
	<nav>
		<!-- <svg viewBox="0 0 2 3" aria-hidden="true">
			<path d="M0,0 L1,2 C1.5,3 1.5,3 2,3 L2,0 Z" />
		</svg> -->
		<!-- <ul>
			<li aria-current={$page.url.pathname === '/' ? 'page' : undefined}>
				<a href="/" class="text-white">ManaMurah</a>
			</li>
		</ul> -->

		<a href="/" class="text-white h-full">
			<div class="flex justify-center items-center  hover:bg-[#ffffff80] rounded-sm p-1">
				<img src={logo} alt="ManaMurah" class="w-[32px] h-[32px] max-h-[100px] max-w-[100px] mr-2"/>
				ManaMurah
			</div>
		</a>

		<!-- <ul>
			<li aria-current={$page.url.pathname === '/' ? 'page' : undefined}>
			</li>

		</ul> -->
		<!-- <svg viewBox="0 0 2 3" aria-hidden="true">
			<path d="M0,0 L0,3 C0.5,3 0.5,3 1,2 L2,0 Z" />
		</svg> -->
	</nav>

	<div class="corner mr-12">
		<!-- {#if DEVMODE} -->
			<div class="flex justify-center items-center gap-4 h-full mt-2 pb-2">
				<a href="/kedai" class="text-white text-xs hover:bg-[#f2f2f280] px-4 {$page.url.pathname === '/' ? 'rounded-md' : 'rounded-t-md'}">
					Premis
				</a>
				<!-- <a href="/barang" class="text-white text-xs hover:bg-[#f2f2f280] p-4 {$page.url.pathname === '/' ? 'rounded-md' : 'rounded-t-md'}">
					Barang
				</a> -->
			</div>
		<!-- {/if} -->
	</div>

</header>


{#if $navigating && showOverlay}
	<div class="overlay">
		<Clock size="120" color="#FF3E00" unit="px" duration="10s" />
	</div>
{/if}

<style>
	header {
		display: flex;
		justify-content: space-between;
	}

	.corner {
		width: 3em;
		height: 3em;
	}

	.corner a {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
	}

	.corner img {
		width: 2em;
		height: 2em;
		object-fit: contain;
	}

	nav {
		display: flex;
		justify-content: center;
		/* --background: rgba(255, 255, 255, 0.7); */
	}

	ul {
		position: relative;
		padding: 0;
		margin: 0;
		height: 3em;
		display: flex;
		justify-content: center;
		align-items: center;
		list-style: none;
		background: var(--background);
		background-size: contain;
	}

	li {
		position: relative;
		height: 100%;
	}

	li[aria-current='page']::before {
		--size: 6px;
		content: '';
		width: 0;
		height: 0;
		position: absolute;
		top: 0;
		left: calc(50% - var(--size));
		border: var(--size) solid transparent;
		border-top: var(--size) solid var(--color-theme-1);
	}

	nav a {
		display: flex;
		height: 100%;
		align-items: center;
		padding: 0 0.5rem;
		/* color: var(--color-text); */
		font-weight: 700;
		font-size: 0.8rem;
		text-transform: uppercase;
		letter-spacing: 0.1em;
		text-decoration: none;
		transition: color 0.2s linear;
	}

	a:hover {
		color: var(--color-theme-1);
	}


	/* https://svelte.dev/blog/view-transitions */

	header {
		display: flex;
		justify-content: space-between;
		view-transition-name: header;
	}

	li[aria-current='page']::before {
		/* other existing rules */
		view-transition-name: active-page;
	}



	.overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: #427FED80; /* semi-transparent */
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 1000; /* ensure the overlay is above other content */
	}

</style>
